import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'

const TagSingle = ({ data, pageContext }) => {
  if (!pageContext.posts) {
    return <div className="content" />
  }

  return (
    <div className="page">
      <div className="container">
        <Helmet
          title={`${data.site.siteMetadata.blogTitle} - tags: ${pageContext.tagName}`}
        />
        <Header
          siteTitle={data.site.siteMetadata.blogTitle}
          siteSubTitle={data.site.siteMetadata.subtitle}
        />
        <div className="content">
          <h1>{pageContext.tagName}</h1>
          <ul className="tags">
            {pageContext.posts.map((post, index) => {
              return (
                <li key={index} className="icon-tag">
                  <Link to={post.frontmatter.path}>
                    {post.frontmatter.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <Footer
        copyright={data.site.siteMetadata.copyright}
        repositoryName={data.site.siteMetadata.repositoryName}
        repositoryLink={data.site.siteMetadata.repositoryLink}
      />
    </div>
  )
}

TagSingle.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query TagSingleQuery {
    site {
      siteMetadata {
        blogTitle
        subtitle
        copyright
        repositoryName
        repositoryLink
      }
    }
  }
`

export default TagSingle
